import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useTheme } from '@material-ui/core/styles';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import {
  IconBtnCopy,
  IconBtnDelete,
  IconBtnEdit
} from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticClientField,
  FilterSearchElasticExecutorField,
  FilterSearchElasticOrderField,
  FilterSearchField,
  FilterSearchGeoElasticAddress,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { ordersActions } = this.props;
      ordersActions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { ordersActions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить заявку?'
      })
    );

    if (res) {
      try {
        await ordersActions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleShowCopy = (row) => {
    const { history, location, basePath } = this.props;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: row.id }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ordersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const theme = useTheme();
  const { handleDelete, handleShowCopy, basePath = '/', record } = props;

  const { params = {} } = record;

  const { filter = {} } = params;

  const lastUsedOrderId = sessionStorage.getItem('last_used_order_id');
  return (
    <Screen title="Заявки">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={
          <Rbac operation="orders:create">
            <AddBtn to={`${basePath}/add`} />
          </Rbac>
        }
        rowStyle={(row) => {
          const style = {};

          if (row.is_urgently === 'yes') {
            style.background = theme.colors.lightRed;
          } else if (row.is_finished === 'yes') {
            style.background = theme.colors.lightGreen;
          }
          return style;
        }}
        rowClass={(row) => {
          if (lastUsedOrderId && Number(lastUsedOrderId) === row.id) {
            return 'used-list-row';
          }
          return '';
        }}
        filter={
          <Filter>
            <FilterSearchElasticOrderField
              source="order_id"
              label="Поиск"
              alwaysOn
              searchType=""
              searchPath="id"
            />
            <FilterSearchField source="id" label="ID" alwaysOn searchType="" />
            <FilterDateRangeField
              source="date_from"
              labels={['Заявка,с', 'Заявка,по']}
              alwaysOn
              maxWidth={275}
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
            <FilterSearchElasticClientField
              source="client_id"
              alwaysOn
              label="Клиент"
            />
            <FilterSearchSelectField
              source="user_id"
              alwaysOn
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
              getValue={(o) => o.id}
            />
            <FilterSelectField
              source="is_active"
              label="Активен"
              alwaysOn
              options={[
                { value: 'all', label: 'Все' },
                { value: 'yes', label: 'Активные' },
                { value: 'no', label: 'Не активные' }
              ]}
            />
            <FilterSearchField
              source="agreement_number"
              label="Номер договора"
            />
            <FilterSearchElasticExecutorField
              source="executor_id"
              label="Дезинфектор"
              customOption={{
                id: 'null',
                name: 'Не указан'
              }}
            />
            <FilterSearchGeoElasticAddress
              source="locality_id,street_id,address_house"
              label="Адрес"
              filter={filter}
              maxWidth={600}
            />
            <FilterSearchSelectField
              source="pay_type_id"
              label="Способ оплаты"
              requestPath="/pay_types"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSearchSelectField
              source="reasons.reason_id"
              label="Вредители"
              requestPath="/reasons"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
              disableClearable={true}
            />
            <FilterSearchSelectField
              source="methods.method_id"
              label="Услуги"
              requestPath="/methods"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => (o ? o.id : null)}
              disableClearable={true}
            />
            <FilterSearchField
              source="client_name"
              label="Клиент (имя)"
              searchType="like%"
              searchPath="client.name"
            />
            <FilterSelectField
              source="is_urgently"
              label="Срочно"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Срочно' },
                { value: 'no', label: 'Не cрочно' }
              ]}
            />
            <FilterSelectField
              source="is_finished"
              label="Выполнен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Выполнен' },
                { value: 'no', label: 'Не выполнен' }
              ]}
            />
            <FilterSelectField
              source="is_qc"
              label="Контроль качества"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_feedback"
              label="Оставлен чек"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_repeat"
              label="Повторная заявка"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Клиент"
          source="client"
          format={(client) => {
            if (!client) return '';
            return client.name;
          }}
        />
        <Field label="Номер" source="client.phone" type="phone" />
        <Field
          label="Цена"
          source="price"
          type="price"
          sortable
          align="right"
        />
        <Field
          label="Дата заявки"
          type="daterange"
          source="*"
          sortable="date_from"
        />
        <Field
          label="Дезинфектор"
          source="executor"
          format={(executor) => {
            if (!executor) return '';
            return executor.name;
          }}
        />
        <Field
          label="Принял"
          source="user"
          format={(item) => {
            if (!item) return '';
            return `${[item.first_name, item.last_name].join(' ')}`;
          }}
        />
        <Field
          label="Выполнен"
          source="is_finished"
          type="boolean"
          format={(v) => v === 'yes'}
        />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field source="id" isAction>
          {({ value, row }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Rbac operation="orders:create">
                  <IconBtnCopy onClick={() => handleShowCopy(row)} />
                </Rbac>
                <Rbac operation="orders:update">
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                </Rbac>
                <Rbac operation="orders:delete">
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                </Rbac>
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const OrdersList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default OrdersList;
